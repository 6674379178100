import React, { useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Avatar, Menu, MenuButton, MenuList, MenuItem, Flex, Text, IconButton } from '@chakra-ui/react';
import logo from './img/mundo.png';
import { HamburgerIcon } from '@chakra-ui/icons';

const Navbar = () => {
    const navBarRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path;
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]);

    const handleClick = (path) => {
        navigate(path);
    };
   
    

    const isContactUs = location.pathname === '/contact-us';
    const isCareers = location.pathname === '/careers';
    const isOurServices = location.pathname === '/our-services';
    const isAboutUs = location.pathname === '/about-us';
    const isHome = location.pathname === '/';


    return (
        <Flex className='navbarflex'>
            <Flex pl={6}>
                <Avatar as={Link} to="/" width={"45px"} height={"45px"} mt={2} mb={2} borderRadius={0} src={logo} alt="Home" />
            </Flex>


            <Flex className='navbarfivesections' >
                <Text as={Link} to="/" cursor={'pointer'} fontWeight={"semibold"} mr={8} color={isHome ? "#7a86e6" : "#2c3470"} _hover={{ color: isHome ? '#7a86e6' : '#7a86e6' }}
                  
                >
                    Home
                </Text>
                <Text as={Link} to="/about-us" cursor={'pointer'} fontWeight={"semibold"} mr={8} color={isAboutUs ? "#7a86e6" : "#2c3470"} _hover={{ color: isAboutUs ? '#7a86e6' : '#7a86e6' }}
                   
                >
                    About Us
                </Text>
                <Text as={Link} to="/our-services" cursor={'pointer'} fontWeight={"semibold"} mr={8} color={isOurServices ? "#7a86e6" : "#2c3470"} _hover={{ color: isOurServices ? '#7a86e6' : '#7a86e6' }}
                   
                >
                    Our Services
                </Text>
                <Text as={Link} to="/careers" cursor={'pointer'} fontWeight={"semibold"} mr={8} color={isCareers ? "#7a86e6" : "#2c3470"} _hover={{ color: isCareers ? '#7a86e6' : '#7a86e6' }}
                   
                >
                    Careers
                </Text>
                <Text mr={4} as={Link} to="/contact-us" cursor={'pointer'} fontWeight={"semibold"} color={isContactUs ? "#7a86e6" : "#2c3470"} _hover={{ color: isContactUs ? '#7a86e6' : '#7a86e6' }}
                   
                >
                    Contact Us
                </Text>
            </Flex>

            <Flex className='hamburguerflex'>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon />}
                        variant='outline'
                        borderColor={"white"}
                        fontSize={"32px"}
                        mt={"15px"}
                    />
                    <MenuList>
                        <MenuItem as={Link} to="/" bg={isActive('/') ? "#e6e6e6" : "transparent"}>
                            Home
                        </MenuItem>
                        <MenuItem as={Link} to="/about-us"  bg={isActive('/about-us') ? "#e6e6e6" : "transparent"}>
                            About Us
                        </MenuItem>
                        <MenuItem as={Link} to="/our-services" bg={isActive('/our-services') ? "#e6e6e6" : "transparent"}>
                            Our Services
                        </MenuItem>
                        <MenuItem as={Link} to="/careers"  bg={isActive('/careers') ? "#e6e6e6" : "transparent"}>
                            Careers
                        </MenuItem>
                        <MenuItem as={Link} to="/contact-us"  bg={isActive('/contact-us') ? "#e6e6e6" : "transparent"}>
                            Contact Us
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Flex>


        </Flex>
    );
};

export default Navbar;
