import './App.css'
import React, { useState } from 'react'; 
import axios from 'axios';
import { Box, Button, Flex, Text, Textarea, Image, Input } from '@chakra-ui/react';
import Woman_2 from './img/Woman_2.svg'




const Mapsandform = () => {

    const BASE_URL = 'https://it.dedicatedpeople.us/api/sendEmail';

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const clearForm = () => {
        setFormData({
            name: '',
            email: '',
            phone: '',
            message: ''
        });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(BASE_URL, formData);
            clearForm();
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
        }
    };

    return (
        <Flex className='mapformwhite' >
            <Box className='Woman_2'>
                <Image src={Woman_2} />
            </Box>
            <Flex className='mapsbgg'  >
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d232.79995720653056!2d-89.60435993157996!3d21.000680164465983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sus!4v1714084112934!5m2!1ses-419!2sus" width="100%" height="750" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Flex>
            <Flex className='contactbox'  >
                <Text className='explore_partnership'> Explore partnership benefist now!</Text>
                <Text className='briefly'> We'll contact you briefly.</Text>
                <Flex flexDirection={"column"} width={"100%"}  >
                    <Text color={"#2c3470"} mt={4} mb={2} fontSize={"20px"} fontWeight={500} width={"max-content"} >Name</Text>
                    <Input
                        bg={"#f2f2f2"}
                        borderColor={"white"}
                        name='name'
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <Text color={"#2c3470"} mt={4} mb={2} fontSize={"20px"} fontWeight={500} width={"max-content"}>Email</Text>
                    <Input
                        bg={"#f2f2f2"}
                        borderColor={"white"}
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <Text color={"#2c3470"} mt={4} mb={2} fontSize={"20px"} fontWeight={500} width={"max-content"} >Phone</Text>
                    <Input
                        bg={"#f2f2f2"}
                        borderColor={"white"}
                        name='phone'
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    <Text color={"#2c3470"} mt={4} mb={2} fontSize={"20px"} fontWeight={500} width={"max-content"}>Message</Text>
                    <Textarea
                        bg={"#f2f2f2"}
                        borderColor={"white"}
                        maxHeight={"250px"}
                        minHeight={"180px"}
                        name='message'
                        value={formData.message}
                        onChange={handleChange}
                    />
                    <Flex flexDirection={"column"} width={"100%"} justifyContent={"center"} alignItems={"center"}  >
                        <Button class="btnsendandtest" onClick={handleSubmit}>
                            Send!
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex >
    );
};

export default Mapsandform;
