import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import OurServices from './OurServices';
import { ChakraProvider } from '@chakra-ui/react';
import reportWebVitals from './reportWebVitals';
import AboutUs from './AboutUs';
import Careers from './Careers';
import ContactUs from './ContactUs';
import Carousel5 from './Carousel5'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider>
    <Router>
      <Routes>
        <Route path="/" element={<React.StrictMode><App /></React.StrictMode>} />
        <Route path="/our-services" element={<React.StrictMode><OurServices /></React.StrictMode>} />
        <Route path="/about-us" element={<React.StrictMode><AboutUs /></React.StrictMode>} />
        <Route path="/careers" element={<React.StrictMode><Careers /></React.StrictMode>} />
        <Route path="/contact-us" element={<React.StrictMode><ContactUs /></React.StrictMode>} /> 
      </Routes>
    </Router>
  </ChakraProvider>,
);

reportWebVitals();