import './App.css';
import React, { } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import { FaFacebookF } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaLinkedinIn } from "react-icons/fa";
import Contact from "./img/imgPersons/contac_us.png"
import { AiFillInstagram } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import email from './img/icon_email.png'
import { FiFacebook } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FiLinkedin } from "react-icons/fi";
import Mapsandform from './Mapsandform';





const ContactUs = () => {
    return (
        <Box display={'block'} position="relative">
            <Navbar />
            <Box mt={"54px"} height={'max-content'} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Text className='Ready-Partnership-Customer'> Ready to <span style={{ color: '#fabb1c', fontStyle: 'italic' }}>listen.</span> </Text>
                <Text className='inquiries'> For inquiries, partnerships, or information, reach out to us. We're here to promptly assist you in unlocking new possibilities. </Text>
                <Box className='Contact' > <Image borderRadius={"20px"} src={Contact} /> </Box>
                <Flex className='socialmedia'>

                    <a href="https://linktr.ee/dedicatedpeoplehiring" border='1px' target="_blank" rel="noopener noreferrer">
                        <FiFacebook className='iconsContact' />
                    </a>

                    <a href="https://www.instagram.com/dedicatedpeople.staff/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram className='iconsContact' />
                    </a>

                    <a href="mailto:contact@dedicatedpeople.us" target="_blank" rel="noopener noreferrer" >
                        <MdOutlineEmail className='iconsContact' />
                    </a>

                    <a href="https://www.linkedin.com/showcase/dedicated-people-hiring/" target="_blank" rel="noopener noreferrer">
                        <FiLinkedin className='iconsContact' />
                    </a>




                </Flex>
                <Flex className='contactlocations' >
                    <Flex className='boxcontactus'   >
                        <Text className='ContactLocation'>Contact Us</Text>
                        <Text className='ContactInfo'> contact@dedicatedpeople.us <br /> Main Office: (337) 314-9711 <br /> Office: (999) 442-8084 </Text>
                    </Flex>
                    <Flex className='boxcontactus'   >
                        <Text className='ContactLocation'>Locations</Text>
                        <Text className='directions'> Main Office 2851 Johnston Street <br /> #543 Lafayette, LA 70503  <br /> <br /> Office Calle 20 212A Col. México,  <br />
                            México Oriente 97317 Mérida, Yuc. </Text>
                    </Flex>
                </Flex>
            </Box>
            <Mapsandform />
            <Footer />
        </Box>
    );
};
export default ContactUs;