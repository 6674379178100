import './App.css';
import { Box, Button, Flex, Text, Textarea, Image } from '@chakra-ui/react';
import React, { useRef } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Mapsandformbgg from './Mapsandformbgg';
import { FaStar } from "react-icons/fa";
import Carousel from './Carousel';
import Office from './img/imgPersons/Work_2.png'
import Image_1 from './img/imgPersons/gaboo.png'
import Woman_1 from './img/imgPersons/Sara.png'
import { useNavigate, Link } from 'react-router-dom';
import Naeo from './img/NAEO.png'
import Atsi from './img/ATSI.png'
import Asta from './img/Astaa.png'
import Camx from './img/Camx.png'
import Snug from './img/Snug.png'
import Tune from './img/Tune.png'
import Gltsa from './img/Gltsa.png'
import Wsta from './img/WSTA.png'

const App = () => {
  const estrellas = [];
  for (let i = 0; i < 5; i++) {
    estrellas.push(<FaStar key={i} />);
  }
  const navBarRef = useRef(null);
  const navigate = useNavigate();
  const scrollToNavBar = () => {
    if (navBarRef.current) {
      const originalPosition = navBarRef.current.style.position;
      navBarRef.current.style.position = 'relative';
      navBarRef.current.scrollIntoView({ behavior: 'smooth' });
      navBarRef.current.style.position = originalPosition;
    }
  };
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <Box ref={navBarRef} display={'block'} position="relative"  >
      <Navbar />
      <Flex className='staffing'>
        <Flex className='staffingflexbox'  >
          <Text className='staffingtitle'> Staffing made <span style={{ color: '#fabb1c', fontStyle: 'italic' }}>easy.</span> </Text>
          <Text className='workingclose'> "We're a dedicated TAS staffing agency, working closely with you as an extension of your team." </Text>
          <Button class="btnbluecontact" as={Link} to="/contact-us" onClick={() => handleClick('/contact-us')}> Contact Us </Button>
        </Flex>
        <Flex className='ImagePerson1Home'>
          <Image src={Image_1} />
        </Flex>
      </Flex>

      <Flex bg={"#f2f2f2"} height={'max-content'} mt={"0%"} pt={"5%"} marginBottom={'3%'} paddingBottom={'4%'} flexDirection={"column"} boxShadow={"0px 0px 20px 8px #cccccc"} alignItems={"center"} overflowX={"hidden"} >
        <Text class='Meeting_your'> Meeting your <span style={{ color: '#fabb1c', fontFamily: 'Open Sans', fontWeight: 'bold' }}>24/7</span> staffing needs. </Text>
        <Text className='hiring_through' zIndex={900}> By hiring through us, forget about all the added cost of an in-house employee: </Text>
        <Flex width={"max-content"} flexDirection={"column"} zIndex={900}>
          <Flex className='flexfourpoints'>
            <Text class='staffing_needs'> • Payroll Taxes </Text>
            <Text class='staffing_needs'> • Medicare </Text>
            <Text class='staffing_needs'> • Computers </Text>
            <Text class='staffing_needs'> • Equipment </Text>
          </Flex>
          <Flex className='flexfourpoints'>
            <Text class='staffing_needs'> • Insurance </Text>
            <Text class='staffing_needs'> • Social Security </Text>
            <Text class='staffing_needs'> • Office Supplies </Text>
            <Text class='staffing_needs'> • And more... </Text>
          </Flex>
        </Flex>
        <Box className='Officeimg'> <Image borderRadius={"30px"} marginTop={"3%"} src={Office} /> </Box>
        <Box mt={16} mb={10}>
          <Button class="contactandsucces" as={Link} to="/contact-us" onClick={() => handleClick('/contact-us')}> Contact us to start saving!</Button>
        </Box>
      </Flex>
      <Carousel />

      <Flex height={'max-content'} flexDirection={"row"} justifyContent={"center"}>
        <Flex className='tobededicated'>
          <Text class='WantToBe' > Want to be <span className='maindedicated'>Dedicated?</span> </Text>
          <Text className='Work_with_us' ><span style={{ fontWeight: 'bold' }}>Work with us!</span> We are looking for positive people who are comfortable with multitasking,
            have an understanding of phone etiquette, excellent verbal and written communication skills and want to grow with us.
          </Text>
          <a className='btnapplynow' href="https://hiring.dedicatedpeople.us/vacancy-page/" target="_blank" rel="noopener noreferrer" > Apply now </a>
        </Flex>
        <Box className='Woman_1'> <Image src={Woman_1} /> </Box>
      </Flex>
      <Flex className='PartnersSponsors'>
        <Flex className='Partners'>
          <Text className='partners_sponsors_Font MB'>
            OUR PROUD PARTNERS:
          </Text>
          <Flex className='flextwopartners'>
            <Flex className='spacePartnersSponsors'>
              <Flex width={"max-content"} alignItems={"center"} flexDirection={"column"} height={"max-content"}>
                <Flex className='fourpartners'>
                  <Image width={"100%"} src={Naeo} />
                </Flex>

                <Text className='subtitlepartners'>
                  Preferred Vendor
                </Text>
              </Flex>
              <Flex width={"max-content"} alignItems={"center"} flexDirection={"column"} pt={"7%"} justifyContent={"space-between"} height={"100%"}>
                <Flex className='fourpartners'>
                  <Image width={"100%"} src={Atsi} />
                </Flex>

                <Text className='subtitlepartners'>
                  Auxiliary Member
                </Text>
              </Flex>
            </Flex>
            <Flex className='spacePartnersSponsors'>
              <Flex ml={"2%"} width={"max-content"} alignItems={"center"} flexDirection={"column"} height={"100%"} pt={"7%"} justifyContent={"space-between"}>
                <Flex className='fourpartners'>
                  <Image width={"100%"} src={Asta} />
                </Flex>

                <Text className='subtitlepartners'>
                  Vendor Member
                </Text>
              </Flex>
              <Flex width={"max-content"} alignItems={"center"} flexDirection={"column"} height={"100%"}>
                <Flex className='fourpartners MT'>
                  <Image width={"100%"} src={Camx} />
                </Flex>

                <Text color={"#424ea8"} fontWeight={500} mt={"6%"} textAlign={"center"} width={"80%"}>
                  Preferred Vendor
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex className='Partners'>
          <Text className='partners_sponsors_Font'>
            PROUD CONFERENCE SPONSORS:
          </Text>
          <Flex className='flextwopartners borderleftsponsord '>
            <Flex className='spacePartnersSponsors'>
              <Flex width={"max-content"} alignItems={"center"} flexDirection={"column"} height={"max-content"}>
                <Flex className='foursponsors'>
                  <Image width={"100%"} src={Snug} />
                </Flex>


              </Flex>
              <Flex width={"max-content"} alignItems={"center"} flexDirection={"column"} justifyContent={"space-between"} height={"100%"}>
                <Flex className='foursponsors'>
                  <Image width={"100%"} src={Tune} />
                </Flex>


              </Flex>
            </Flex>
            <Flex className='spacePartnersSponsors'>
              <Flex width={"max-content"} alignItems={"center"} flexDirection={"column"} height={"100%"} justifyContent={"space-between"}>
                <Flex className='foursponsors'>
                  <Image width={"100%"} src={Gltsa} />
                </Flex>
              </Flex>
              <Flex width={"max-content"} alignItems={"center"} flexDirection={"column"} height={"100%"}>
                <Flex className='foursponsors'>
                  <Image width={"100%"} src={Wsta} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Mapsandformbgg />
      <Footer />
    </Box>
  );
};
export default App;