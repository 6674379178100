import './App.css';
import { Box, Flex, Text, background } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { RiDoubleQuotesR } from "react-icons/ri";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { FaStar } from "react-icons/fa";

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(1);

    const [transformValue, setTransformValue] = useState("");

    const estrellas = [];
    for (let i = 0; i < 5; i++) {
        estrellas.push(<FaStar key={i} />);
    }

    const testimonie = [];
    for (let i = 0; i < 3; i++) {
        testimonie.push(<FaStar key={i} />);
    }

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonie.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonie.length - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
        const updateTransformValue = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 360 && screenWidth <= 767) {
                setTransformValue(`translateX(-${currentIndex * 300}px)`);
            } else if (screenWidth >= 768 && screenWidth <= 1200) {
                setTransformValue(`translateX(-${currentIndex * 330}px)`);
            } else if (screenWidth >= 1201) {
                setTransformValue(`translateX(-${currentIndex * 190}px)`);
            }
        };

        updateTransformValue(); // Call on initial render
        window.addEventListener("resize", updateTransformValue);

        // Cleanup
        return () => {
            window.removeEventListener("resize", updateTransformValue);
        };
    }, [currentIndex]);


    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box className='carouselbox'  >
                <Flex
                    mt={"6%"}
                    height={"max-content"}
                    width={"max-content"}
                    transform={transformValue}
                    transition="transform 0.5s ease-in-out"
                >
                    <Box className='testimonies'>
                        <Flex className='stars'>{estrellas}</Flex>
                        <Text className='subtittleCarousel'> Timothy Stroud, Manager</Text>
                        <Text className='titlecarousel'>Training, Quality & Operations Support</Text>
                        <Text className='carrnametext'> “Dedicated People has been a reliable partner for Anserve, staffing well-spoken bilingual
                            agents that cover multiple shifts for us. They have an excellent training program, coupled
                            with solid ongoing coaching reinforcement for continual improvement. When COVID-19 hit,
                            their IT staff displayed fast-acting agility in arranging for all of their agents to be able to work
                            from home with no disruption of availability or top-quality service. All in all, we have been
                            very satisfied with our relationship with Dedicated People and see a bright future with them.” </Text>
                        <Box className='boxdoublequotes'>
                            <Box className='icondoublequotes'>  <RiDoubleQuotesR /> </Box>
                        </Box>
                    </Box>
                    <Box className='testimonies'>
                        <Flex className='stars'>{estrellas}</Flex>
                        <Text className='subtittleCarousel'> Celia Vincent Broussard </Text>
                        <Text className='titlecarousel'>Training, Quality & Operations Support</Text>
                        <Text className='carrnametext'> “Dedicated People was a lifesaver to Southwest Call Center, Inc. in the early days after
                            Hurricane Laura made landfall in late August of 2020. Our area was without power and
                            electricity, and our staff was not able to connect to our system. We had just begun our
                            relationship with Dedicated People, and with their help, we were able to quickly add
                            additional agents so that we would be able to answer calls. They have been wonderful to
                            work with, and I look forward to continuing our relationship with them.” </Text>
                        <Box className='boxdoublequotes'>
                            <Box className='icondoublequotes'>  <RiDoubleQuotesR /> </Box>
                        </Box>
                    </Box>
                    <Box className='testimonies'>
                        <Flex className='stars'>{estrellas}</Flex>
                        <Text className='subtittleCarousel'> Andrew Breischaft </Text>
                        <Text className='titlecarousel'>Training, Quality & Operations Support</Text>
                        <Text className='carrnametext'> "We're highly impressed with Dedicated People! After numerous unsuccessful hires, we turned to them and now
                            have 3 skilled and dedicated operators. Beyond providing trained employees, Dedicated People maintains excellent communication and support
                            even after the training period. The entire team, from owner to training staff, is professional and courteous. We wholeheartedly recommend Dedicated People!" </Text>
                        <Box className='boxdoublequotes'>
                            <Box className='icondoublequotes'>  <RiDoubleQuotesR /> </Box>
                        </Box>
                    </Box>
                </Flex>
            </Box>
            <Flex mt={10} mb={10}>
                <Box
                    width={"50px"}
                    height={"50px"}
                    fontSize={"40px"}
                    cursor={"pointer"}
                    color={"#cccccc"}
                    p={1}
                    onClick={handlePrev}
                >
                    {" "}
                    <SlArrowLeft />{" "}
                </Box>
                <Text fontSize={"25px"} color={"#2c3470"} fontWeight={"bold"} ml={6} mr={6}>
                    {" "}
                    {currentIndex + 1} - 3 Testimonies{" "}
                </Text>
                <Box
                    width={"50px"}
                    height={"50px"}
                    fontSize={"40px"}
                    cursor={"pointer"}
                    color={"#cccccc"}
                    p={1}
                    onClick={handleNext}
                >
                    {" "}
                    <SlArrowRight />{" "}
                </Box>
            </Flex>
        </Box>
    );
};

export default Carousel;
