import React, { } from 'react';
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import { MdEmail } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import email from './img/icon_email.png'

const Footer = () => {
    return (
        <Box id='footer'>
            <Box className='footer' >

                <Flex className='boxcontactandlocations' >
                    <Text className='ContactName'>Contact Us</Text>
                    <Text className='infoboxfooter' >
                        contact@dedicatedpeople.us <br />
                        Main Office: (337) 314-9711 <br />
                        Office: (999) 442-8084
                    </Text>
                </Flex>
                <Flex className='boxcontactandlocations' >
                    <Text className='ContactName'>Locations</Text>
                    <Text className='infoboxfooter' >
                        Main Office 2851 Johnston Street,  <br />
                        #543 Lafayette, LA 70503.
                        <br />
                        <br />
                        Office Calle 20 212A Col. México,  <br />
                        México Oriente 97317 Mérida, Yuc.
                    </Text>
                </Flex>
                <Flex className='socialmedia2'>

                    <a href="https://linktr.ee/dedicatedpeoplehiring" target="_blank" rel="noopener noreferrer">
                        <FaFacebookSquare className='iconsfooter' />
                    </a>

                    <a href="https://www.instagram.com/dedicatedpeople.staff/" target="_blank" rel="noopener noreferrer">
                        <FaInstagramSquare className='iconsfooter'  />
                    </a>

                    <a href="mailto:contact@dedicatedpeople.us" target="_blank" rel="noopener noreferrer">
                        <img src={email} alt="Correo electrónico" />
                    </a>

                    <a href="https://www.linkedin.com/showcase/dedicated-people-hiring/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin className='iconsfooter' />
                    </a>


 

                </Flex>
            </Box>
        </Box>
    );
};
export default Footer;