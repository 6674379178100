import './App.css';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { RiDoubleQuotesR } from "react-icons/ri";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { FaStar } from "react-icons/fa";
import icono_3 from "./img/icono_3.svg"
import icono_2 from "./img/icono_2.svg"
import icono_1 from "./img/icono_1.svg"
import ups_2 from "./img/ups_2.svg"
import Ups_1 from "./img/Ups_1.svg"


const Carousel5 = () => {
    const [currentIndex, setCurrentIndex] = useState(2);

    const [transformValue, setTransformValue] = useState("");

    const estrellas = [];
    for (let i = 0; i < 5; i++) {
        estrellas.push(<FaStar key={i} />);
    }

    const testimonie = [];
    for (let i = 0; i < 5; i++) {
        testimonie.push(<FaStar key={i} />);
    }

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonie.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonie.length - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
        const updateTransformValue = () => {
          const screenWidth = window.innerWidth;
          if (screenWidth >= 360 && screenWidth <= 767) {
            setTransformValue(`translateX(-${currentIndex * 385}px)`);
          } else if (screenWidth >= 1024) {
            setTransformValue(`translateX(-${currentIndex * 310}px)`);
          }
        };
    
        updateTransformValue(); // Call on initial render
        window.addEventListener("resize", updateTransformValue);
    
        // Cleanup
        return () => {
          window.removeEventListener("resize", updateTransformValue);
        };
      }, [currentIndex]);

      useEffect(() => {
        const updateTransformValue = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 360 && screenWidth <= 767) {
                setTransformValue(`translateX(-${currentIndex * 366}px)`);
            } else if (screenWidth >= 768 && screenWidth <= 1200) {
                setTransformValue(`translateX(-${currentIndex * 380}px)`);
            } else if (screenWidth >= 1201) {
                setTransformValue(`translateX(-${currentIndex * 185}px)`);
            }
        };
    
        updateTransformValue(); // Call on initial render
        window.addEventListener("resize", updateTransformValue);
    
        // Cleanup
        return () => {
            window.removeEventListener("resize", updateTransformValue);
        };
    }, [currentIndex]);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={10} >
            <Box className='carouselboxfive'>
                <Flex
                    height={"max-content"}
                    width={"max-content"}
                    transform={transformValue}
                    transition="transform 0.5s ease-in-out"
                >
                    <Box className='carousel5' >
                        <Box width={"270px"} height={"270px"} >  <Image src={Ups_1} /> </Box>
                        <Text className='subtittleCarousel'> Trained Dedicated <br /> Representatives </Text>
                        <Text className='carrnametext'>
                            By creating a solid, stable work
                            environment, our partnership with your
                            company develops highly trained
                            representatives who are dedicated to
                            offering top-quality customer service.
                        </Text>
                    </Box>
                    <Box className='carousel5' >
                        <Box width={"270px"} height={"270px"} >  <Image src={icono_2} /> </Box>
                        <Text className='subtittleCarousel'> Understanding & <br /> Following the procedures </Text>
                        <Text className='carrnametext'> Our trainers work closely with our clients to understand
                            their processes and procedures.
                        </Text>
                    </Box>
                    <Box className='carousel5' >
                        <Box width={"270px"} height={"270px"}   >  <Image src={icono_3} /> </Box>
                        <Text className='subtittleCarousel'> Assigning Dedicated <br /> Supervisors </Text>
                        <Text className='carrnametext'> Once you partner up with Dedicated People, your
                            company and representatives will be assigned a team supervisor and account manager
                            at no extra cost to you.
                        </Text>

                    </Box>
                    <Box className='carousel5' >
                        <Box width={"270px"} height={"270px"}   >  <Image src={ups_2} /> </Box>
                        <Text className='subtittleCarousel'> Going above the standards </Text>
                        <Text className='carrnametext'> Our Dedicated People QA staff work with you to
                            understand the standards you require for your in-house representatives and how to
                            meet them.
                        </Text>
                    </Box>
                    <Box className='carousel5' >
                        <Box width={"270px"} height={"270px"}   >  <Image src={icono_1} /> </Box>
                        <Text className='subtittleCarousel'> Constant monitoring </Text>
                        <Text className='carrnametext'> We monitor all our representatives' calls closely to
                            ensure we are providing daily feedback.
                        </Text>
                    </Box>
                </Flex>
            </Box>
            <Flex mt={10}>
                <Box
                    width={"50px"}
                    height={"50px"}
                    fontSize={"40px"}
                    cursor={"pointer"}
                    color={"#cccccc"}
                    p={1}
                    onClick={handlePrev}
                >
                    {" "}
                    <SlArrowLeft />{" "}
                </Box>
                <Text fontSize={"25px"} color={"#2c3470"} fontWeight={"bold"} ml={10} mr={10}>
                    {" "}
                    {currentIndex + 1} - 5{" "}
                </Text>
                <Box
                    width={"50px"}
                    height={"50px"}
                    fontSize={"40px"}
                    cursor={"pointer"}
                    color={"#cccccc"}
                    p={1}
                    onClick={handleNext}
                >
                    {" "}
                    <SlArrowRight />{" "}
                </Box>
            </Flex>
        </Box>
    );
};

export default Carousel5;
