import './App.css';
import { Box, Flex, Text, Button, Image, Avatar } from '@chakra-ui/react';
import React, { } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { StarIcon } from '@chakra-ui/icons';
import Mapsandform from './Mapsandform';
import Carousel5 from './Carousel5';
import About_us_1 from './img/imgPersons/persons.png'
import { useNavigate, Link } from 'react-router-dom';
import img1 from './img/imgPersons/continuous_education.jpg'
import img2 from './img/imgPersons/Technology.jpg'
import img3 from './img/imgPersons/employee-well-beign.jpg'
import img4 from './img/image4.jpg'
import img5 from './img/imgPersons/trust_and_confidence.jpg'
import img6 from './img/imgPersons/equality.jpg'
import picture1 from './img/jason.jpg'
import picture2 from './img/dom.jpg'
import picture3 from './img/alex.jpg'
import picture4 from './img/melissa.jpg'
import picture5 from './img/michelle.jpg'
import picture6 from './img/Jogn.jpg'

const AboutUs = () => {
    const navigate = useNavigate();
    const handleClick = (path) => {
        navigate(path);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Box display={'block'} position="relative" >
            <Navbar />
            <Flex className='boxabout'  >
                <Flex mt={"8%"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} height={"max-content"}  >
                    <Text class='AboutUs'>About <span style={{ color: '#fabb1c', fontStyle: 'italic' }}>Us</span></Text>
                    <Text className='We_understand'>We understand the high demands of the TAS industry. Our goal is to become the staffing
                        partner that helps you succeed and grow your business.
                    </Text>
                    <Button class="contactandsucces" as={Link} to="/contact-us" onClick={() => handleClick('/contact-us')}> Contact Us </Button>
                </Flex>
                <Flex className='About_us_1'> <Image borderRadius={"20px"} src={About_us_1} /> </Flex>
            </Flex>
            <Flex height={'max-content'} bg={"#f2f2f2"} flexDirection={"column"} boxShadow={"0px 0px 20px 8px #cccccc"} alignItems={"center"}>
                <Text class='achieve_and_communication'> How do we <br /> achieve this? </Text>
                <Text className='hiringtext'> By hiring through us, forget about all: </Text>
                <Carousel5 />
            </Flex>
            <Flex height={'max-content'} flexDirection={"column"} alignItems={"center"} >
                <Text className='achieve_and_communication'> Communication <br /> & Individuals </Text>
                <Flex className='achievesix'>
                    <Flex className='boxImagen'>
                        <Box className='sixImagen'>
                            <Box width={"100%"}>
                                <Image className='image_aboutUs' src={img1} />
                            </Box>
                            <Box bg={"#f4bb1c"} borderRadius={"15px"} p={4} width={"max-content"} position="absolute" top="-5" left="-5">

                                <StarIcon color={"white"} boxSize={10} />
                            </Box>
                        </Box>
                        <Text class='subtitle_six_box'>Continuous education</Text>
                        <Text class='subtitle_six_text'>We provide high quality, ongoing training to ensure our representatives are up
                            to date with quality standards and system upgrades.
                        </Text>
                    </Flex>
                    <Flex className='boxImagen'>
                        <Box className='sixImagen'>
                            <Box width={"100%"}>
                                <Image className='image_aboutUs' src={img2} />
                            </Box>
                            <Box bg={"#f4bb1c"} borderRadius={"15px"} p={4} width={"max-content"} position="absolute" top="-5" left="-5">
                                <StarIcon color={"white"} boxSize={10} />
                            </Box>
                        </Box>
                        <Text class='subtitle_six_box'>Technology</Text>
                        <Text class='subtitle_six_text'> Dedicated People’s IT staff stay up to date by regularly researching upgrades
                            and becoming educated with all of the TAS technologies available. We offer adequate IT support.
                        </Text>
                    </Flex>
                </Flex>
                <Flex className='achievesix'>
                    <Flex className='boxImagen'>
                        <Box className='sixImagen'>
                            <Box width={"100%"}>
                                <Image className='image_aboutUs' src={img3} />
                            </Box>
                            <Box bg={"#f4bb1c"} borderRadius={"15px"} p={4} width={"max-content"} position="absolute" top="-5" left="-5">
                                <StarIcon color={"white"} boxSize={10} />
                            </Box>
                        </Box>
                        <Text class='subtitle_six_box'>Employee Well-being</Text>
                        <Text class='subtitle_six_text'>We promote stress relieving, team building activities to keep our staff motivated and happy </Text>
                    </Flex>
                    <Flex className='boxImagen'>
                        <Box className='sixImagen'>
                            <Box width={"100%"}>
                                <Image className='image_aboutUs' src={img4} />
                            </Box>
                            <Box bg={"#f4bb1c"} borderRadius={"15px"} p={4} width={"max-content"} position="absolute" top="-5" left="-5">
                                <StarIcon color={"white"} boxSize={10} />
                            </Box>
                        </Box>
                        <Text class='subtitle_six_box'>Dedication and  <br /> unwavering commitment</Text>
                        <Text class='subtitle_six_text'> “We promote our staff's commitment to the company and our clients through
                            a positive work environment and incentive programs - at no extra cost to our clients.
                        </Text>
                    </Flex>
                </Flex>
                <Flex className='achievesix'>
                    <Flex className='boxImagen'>
                        <Box className='sixImagen'>
                            <Box width={"100%"}>
                                <Image className='image_aboutUs' src={img5} />
                            </Box>
                            <Box bg={"#f4bb1c"} borderRadius={"15px"} p={4} width={"max-content"} position="absolute" top="-5" left="-5">
                                <StarIcon color={"white"} boxSize={10} />
                            </Box>
                        </Box>
                        <Text class='subtitle_six_box'>Trust & confidence</Text>
                        <Text class='subtitle_six_text'>Ideas, suggestions and feedback that promote our company's and our client's benefit come from all of our team members.
                            As Dedicated People we are open to hearing from all our collaborators.
                        </Text>
                    </Flex>
                    <Flex className='boxImagen'>
                        <Box className='sixImagen'>
                            <Box width={"100%"}>
                                <Image className='image_aboutUs' src={img6} />
                            </Box>
                            <Box bg={"#f4bb1c"} borderRadius={"15px"} p={4} width={"max-content"} position="absolute" top="-5" left="-5">
                                <StarIcon color={"white"} boxSize={10} />
                            </Box>
                        </Box>
                        <Text class='subtitle_six_box'>Equality</Text>
                        <Text class='subtitle_six_text'> Dedicated People is an equal opportunity employer. Our positive focus on our
                            team makes us a place where people want to work.
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
            <Flex className='team_pics_box'>
                <Text class='the_team'>The Team</Text>
                <Text class='WePromote'>We promote our staff's commitment to the company and our clients through a positive
                    work environment and incentive programs - at no extra cost to our clients.
                </Text>
                <Flex className='boxteams'>
                    <Flex className='the_teamm' >
                        <Flex className='teampics'>

                            <Image src={picture2} />
                            <Text class='team_names'>Don Courville</Text> <Text class='team_rol'> Owner  </Text>

                        </Flex>

                        <Flex className='teampics'>

                            <Image src={picture1} />
                            <Text class='team_names'>Jason Robichaux</Text> <Text class='team_rol'> Owner  </Text>

                        </Flex>


                        <Flex className='teampics'>

                            <Image src={picture3} />
                            <Text class='team_names'>Alex Ortega</Text> <Text class='team_rol'> Owner  </Text>

                        </Flex>

                    </Flex>
                    <Flex className='the_teamm' >
                        <Flex className='teampics'>

                            <Image src={picture4} />
                            <Text class='team_names'>Melissa Araque</Text> <Text class='team_rol'> Account Manager</Text>

                        </Flex>
                        <Flex className='teampics'>

                            <Image src={picture5} />
                            <Text class='team_names'>Michelle Castro</Text> <Text class='team_rol'> Operations Manager</Text>

                        </Flex>
                        <Flex className='teampics'>

                            <Image src={picture6} />
                            <Text class='team_names'>Juan Maldonado</Text> <Text class='team_rol'> Hiring Manager</Text>

                        </Flex>

                    </Flex>
                </Flex>
            </Flex>
            <Mapsandform />
            <Footer />
        </Box>
    );
};
export default AboutUs;