import './App.css';
import { Box, Text, Flex, Button, Image } from '@chakra-ui/react';
import React, { } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { TriangleUpIcon } from '@chakra-ui/icons';
import Mapsandformbgg from './Mapsandformbgg';
import Our_Services from './img/imgPersons/img_ourServices.png'
import Dude_1 from './img/Dude_1.svg'
import Ou1 from './img/Ou1.svg'
import Ou2 from './img/Ou2.svg'
import Ou3 from './img/Ou3.svg'
import Ou4 from './img/Ou4.svg'
import Ou5 from './img/Ou5.svg'
import Ou6 from './img/Ou6.svg'
import { useNavigate, Link } from 'react-router-dom';

const OurServices = () => {
    const navigate = useNavigate();
    const handleClick = (path) => {
        navigate(path);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Box display={'block'} position="relative"   >
            <Navbar />
            <Flex mt={"54px"} pt={5} height={'max-content'} flexDirection={"column"} alignItems={"center"}  >
                <Text className='OurServices'>Our <span style={{ color: '#fabb1c', fontStyle: 'italic' }}>Services</span> </Text>

                <Box width={"100%"} height={"max-content"} mb={14} position={"relative"} display="flex" justifyContent="center"    >
                    <Text className='Engaging_subtitle'> Engaging in a partnership with us guarantees complete support
                        from our team. </Text>
                    <Image className='Our_Services' src={Our_Services} />
                </Box>
            </Flex>
            <Flex height={'max-content'} flexDirection={"column"} pb={16} bg={"#f2f2f2"} position={"relative"}>
                <Text className='Ready-Partnership-Customer'>Partnership <br /> & Perks</Text>
                <Text className='Team_Support'>Full Team Support</Text>
                <Image src={Dude_1} className='Dude_1' />
                <Flex flexDirection={"row"}  >
                    <Flex className='partner_perks'> 
                        <Text zIndex={20} color={"white"} position="relative" fontSize={"24px"} fontWeight={"bold"} top="230">O1</Text>
                        <TriangleUpIcon color={"#424ea8"} position="relative" left="-10" top="150" boxSize={"130px"} style={{ transform: "rotate(90deg)" }} />
                        <Box className='boxsixaboutus'>
                            <Text class='subtitle_six_box'>Hiring</Text>
                            <Text class='text_six_box'>We take care of the   complete recruiting   and hiring process.</Text>
                        </Box>


                        <Text zIndex={20} color={"white"} position="relative" fontSize={"24px"} fontWeight={"bold"} top="260">O2</Text>
                        <TriangleUpIcon color={"#424ea8"} position="relative" left="-10" top="180" boxSize={"130px"} style={{ transform: "rotate(90deg)" }} />
                        <Box className='boxsixaboutus' >
                            <Text class='subtitle_six_box'>Quantity</Text>
                            <Text class='text_six_box'>Our pricing is tiered based on the number of agents you have on your team.</Text>
                        </Box>

                        <Text zIndex={20} color={"white"} position="relative" fontSize={"24px"} fontWeight={"bold"} top="220">O3</Text>
                        <TriangleUpIcon color={"#424ea8"} position="relative" left="-10" top="140" boxSize={"130px"} style={{ transform: "rotate(90deg)" }} />
                        <Box className='boxsixaboutus'>
                            <Text class='subtitle_six_box'>Paying</Text>
                            <Text class='text_six_box'>You only pay your agent's hourly rate.</Text>
                        </Box>


                        <Text zIndex={20} color={"white"} position="relative" fontSize={"24px"} fontWeight={"bold"} top="255">O4</Text>
                        <TriangleUpIcon color={"#424ea8"} position="relative" left="-10" top="175" boxSize={"130px"} style={{ transform: "rotate(90deg)" }} />
                        <Box className='boxsixaboutus'>
                            <Text class='subtitle_six_box'>Training</Text>
                            <Text class='text_six_box'>We use your training guides to teach agents how to effectively take calls for you.</Text>
                        </Box>

                        <Flex height={"max-content"} flexDirection={"column"} mt={"10%"}>
                            <Text className='IT_Support'>IT Support</Text>
                            <Box mt={-10} >
                                <Text zIndex={20} color={"white"} position="relative" fontSize={"24px"} fontWeight={"bold"} top="255">O1</Text>
                                <TriangleUpIcon color={"#f4bb1c"} position="relative" left="-10" top="175" boxSize={"130px"} style={{ transform: "rotate(90deg)" }} />
                                <Box className='boxsixaboutus'>
                                    <Text class='subtitle_six_box'>Assistance</Text>
                                    <Text class='text_six_box'>Our team provides you with full IT assistance starting from the initial setup process.</Text>
                                </Box>
                            </Box>
                            <Box>
                                <Text zIndex={20} color={"white"} position="relative" fontSize={"24px"} fontWeight={"bold"} top="300">O2</Text>
                                <TriangleUpIcon color={"#f4bb1c"} position="relative" left="-10" top="220" boxSize={"130px"} style={{ transform: "rotate(90deg)" }} />
                                <Box className='boxsixaboutus' >
                                    <Text class='subtitle_six_box'>Top Notch</Text>
                                    <Text class='text_six_box'>Our equipment is updated continuously, and we have the proper redundancy support onsite in case of emergencies.</Text>
                                </Box>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex height={'max-content'} flexDirection={"column"} alignItems={"center"} pt={"2%"}>
                <Text class='Ready-Partnership-Customer'>Customer <br /> Service <span style={{ color: '#fabb1c' }}>Pillars.</span>
                    <Text class='what_make_us_phrase'>
                        What make us different!
                    </Text>
                </Text>
                <Flex className='whatmakeus'>
                    <Flex className='cardmakeus' >
                        <Box width={"200px"} height={"220px"} borderRadius={"30px"} mb={6} bg={"white"} p={2} boxShadow={"5px 5px 10px rgba(0, 0, 0, 0.3)"} > <Image src={Ou1} /> </Box>
                        <Text class='what_make_us'>Efficency and <br /> reducing costs</Text>
                        <Box pt={4}>
                            <Text class='info_six_sox_OurServices'>Partnering with us, you will see your productivity increase as you and your top
                                staff will be able to focus on your business's day-to-day activities.</Text>
                        </Box>
                    </Flex>
                    <Flex className='cardmakeus' >
                        <Box width={"200px"} height={"220px"} borderRadius={"30px"} mb={6} bg={"white"} p={2} boxShadow={"5px 5px 10px rgba(0, 0, 0, 0.3)"} > <Image src={Ou2} /> </Box>
                        <Text class='what_make_us'>Leading-edge <br /> technology</Text>
                        <Box pt={4}>
                            <Text class='info_six_sox_OurServices'>Our fully equipped facility makes remoting into your system and call handling seamless - just like in your office.</Text>
                        </Box>
                    </Flex>
                </Flex>
                <Flex className='whatmakeus'>
                    <Flex className='cardmakeus' >
                        <Box width={"200px"} height={"220px"} borderRadius={"30px"} mb={6} bg={"white"} p={2} boxShadow={"5px 5px 10px rgba(0, 0, 0, 0.3)"} > <Image src={Ou3} /> </Box>
                        <Text class='what_make_us'>Adjustable KPI</Text>
                        <Box pt={4}>
                            <Text class='info_six_sox_OurServices'>We work with you to understand and help our representatives meet the same performance metrics you measure.</Text>
                        </Box>
                    </Flex>
                    <Flex className='cardmakeus' >
                        <Box width={"200px"} height={"220px"} borderRadius={"30px"} mb={6} bg={"white"} p={2} boxShadow={"5px 5px 10px rgba(0, 0, 0, 0.3)"} > <Image src={Ou4} /> </Box>
                        <Text class='what_make_us'>Quality</Text>
                        <Box pt={4}>
                            <Text class='info_six_sox_OurServices'>We handle your calls with the same level of quality you expect from your in-house staff. </Text>
                        </Box>
                    </Flex>
                </Flex>
                <Flex className='whatmakeus'>
                    <Flex className='cardmakeus' >
                        <Box width={"200px"} height={"220px"} borderRadius={"30px"} mb={6} bg={"white"} p={2} boxShadow={"5px 5px 10px rgba(0, 0, 0, 0.3)"} > <Image src={Ou5} /> </Box>
                        <Text class='what_make_us'>Workforce <br /> Management</Text>
                        <Box pt={4}>
                            <Text class='info_six_sox_OurServices'>You can personalize and adjust staffing and schedules to your company needs.</Text>
                        </Box>
                    </Flex>
                    <Flex className='cardmakeus' >
                        <Box width={"200px"} height={"220px"} borderRadius={"30px"} mb={6} bg={"white"} p={2} boxShadow={"5px 5px 10px rgba(0, 0, 0, 0.3)"} > <Image src={Ou6} /> </Box>
                        <Text class='what_make_us'>Equality</Text>
                        <Box pt={4}>
                            <Text class='info_six_sox_OurServices'>Dedicated People is an equal opportunity employer. Our positive focus on our team makes us a place where people want to work.</Text>
                        </Box>
                    </Flex>
                </Flex>
                <Box>
                    <Button class="contactandsucces" as={Link} to="/contact-us" onClick={() => handleClick('/contact-us')}> Let's begin your success!</Button>
                </Box>
            </Flex>
            <Mapsandformbgg />
            <Footer />
        </Box>
    );
};
export default OurServices;