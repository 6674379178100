import './App.css';
import React, { } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import Mapsandformbgg from './Mapsandformbgg';
import Careerss from './img/imgPersons/person_1.png'
import Girl_2 from './img/Girl_2.svg'
import Form from './img/Form.svg'

const Careers = () => {
    return (
        <Box display={'block'} position="relative">
            <Navbar />
            <Box mt={"54px"} height={'max-content'} display="flex"  flexDirection="column" alignItems="center" justifyContent="center" >
                <Text className='WeAre'>
                    We are <span style={{ color: '#fabb1c', fontStyle: 'italic' }}>dedicated.</span>
                </Text>
                <Text className='text_careers_info'>
                    The North American Telephone Answering Service is a fast-paced, high-demand industry. As one of our collaborators, you'll receive continuous
                    training and support from our dedicated team to ensure a long, successful career with Dedicated People.
                </Text>
                <Box className='Careerss'  >
                    <Image src={Careerss} />
                </Box>
            </Box>
            <Flex height={'max-content'} pb={16} bg={"#f2f2f2"} position={"relative"}>
                <Image src={Girl_2} className='Girl_2' />
                <Flex className='becomeD'>
                    <Text className='Become_Dedicated'> Become Dedicated </Text>
                    <Text className='StepByStep'> Step by step </Text>
                    <Flex className='boxnumbers' >
                        <Flex className='become_numbers' >
                            <Box bg={"#2c3470"} borderRadius={"15px"} width={"80px"} height={"110px"} mb={8} position={"relative"} >
                                <Text top="25%" left="24%" fontSize={"40px"} color={"white"} position={"absolute"}>01</Text>
                            </Box>
                            <Box bg={"#424ea8"} borderRadius={"15px"} width={"80px"} height={"130px"} mb={8} position={"relative"} >
                                <Text top="25%" left="24%" fontSize={"40px"} color={"white"} position={"absolute"}>02</Text>
                            </Box>
                            <Box bg={"#5668e0"} borderRadius={"15px"} width={"80px"} height={"150px"} mb={8} position={"relative"} >
                                <Text top="25%" left="24%" fontSize={"40px"} color={"white"} position={"absolute"}>03</Text>
                            </Box>
                            <Box bg={"#7a86e6"} borderRadius={"15px"} width={"80px"} height={"170px"} mb={0} position={"relative"} >
                                <Text top="25%" left="24%" fontSize={"40px"} color={"white"} position={"absolute"}>04</Text>
                            </Box>
                        </Flex>
                        <Flex flexDirection={"column"} width={"70%"}  >

                            <Text className='talk01'> Talk to a recruiter </Text>
                            <Text className='talk01sub'> Submit your information, and one of our Talent Hunters will reach out to you </Text>


                            <Text className='prepare02'> Prepare </Text>
                            <Text className='prepare02sub'>
                                Make sure you have your updated resume printed out and ready </Text>


                            <Text className='interview03'> Interview </Text>
                            <Text className='interview03sub'> Don't forget to be punctual! </Text>


                            <Text className='hired04'> Hired </Text>
                            <Text className='hired04sub'> Once all of the pieces are in place, you'll start
                                training for your career with Dedicated People </Text>

                        </Flex>
                    </Flex>
                    <div id="outer">
                        <a className='applynowbutton' href="https://hiring.dedicatedpeople.us/vacancy-page/" target="_blank" rel="noopener noreferrer" > Apply now </a>
                    </div>
                </Flex>
            </Flex>
            <Box className='BoxJoinTeam'>
                <Box className='Form'>
                    <Image src={Form} />
                </Box>
                <Flex flexDirection={"column"} height={"max-content"} textAlign={"right"}  mt={"6%"}  >
                    <Text className='Join_our'> Join our team! </Text>
                    <Text className='oportunity'> This is an excellent  <br /> opportunity for you, click now  <br /> and complete the test! </Text>
                    <Box className='gototest'>
                        <div id="outer">
                            <a className='btnsendandtest' href="https://form.jotform.com/222726791656062" target="_blank" rel="noopener noreferrer" > Go to test! </a>
                        </div>
                        <Text fontSize={"22px"} fontWeight={"500"} mt={4} fontFamily={"DM Sans"} color={"#2c3470"} paddingRight={10} >Max 10-15 min</Text>
                    </Box>
                </Flex>
            </Box>
            <Mapsandformbgg />
            <Footer />
        </Box>
    );
};

export default Careers;